import React from 'react'
import { Link } from 'gatsby'

const Post = ({ html, frontmatter: { id, title, published, publishedFormatted, tags }, page }) => (
  <article
    className="post"
    itemProp="blogPost"
    itemScope
    itemType="http://schema.org/BlogPosting"
    role="main"
    data-id={id}
  >
    <header className="post__header">
      <h1 className="post__header__title" itemProp="name">
        {title}
      </h1>

      {!page && (
        <>
          <time
            dateTime={published}
            className="post__header__date"
            itemProp="datePublished"
          >
            {publishedFormatted}
          </time>

          {tags.length > 0 && (
            <div className="tags post__header__tags">
              <h3 className="tags__title">
                Posted in: {' '}
              </h3>
              <ul className="tags__list">
                {tags.map((tag, i) => (
                  <React.Fragment key={i}>
                    <li className="tags__list__item">
                      <Link
                        to={`/tag/${tag.slug}`}
                        className="tags__list__item__link"
                      >
                        {tag.name}
                      </Link>
                    </li>
                    {i < tags.length - 1 && <>&nbsp;</>}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </header>

    <div
      className="post__content"
      itemProp="articleBody"
    >
      <div dangerouslySetInnerHTML={{ __html: html }}></div>

      {!page && (
        <p>
          &mdash;
          <span itemProp="author">
            Yasmine
          </span>
        </p>
      )}
    </div>
  </article>
)

export default Post