import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Post from '../components/post'
import { DiscussionEmbed } from 'disqus-react'

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      htmlAst
      excerpt
      frontmatter {
        id
        slug
        title
        published
        publishedFormatted: published(formatString: "dddd Do MMM YYYY")
        dateTime: published(formatString: "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
        image {
          childImageSharp {
            resize(width: 1000) {
              src
              width
              height
            }
          }
        }
        tags {
          slug
          name
        }
      }
    }
  }
`

const PostPage = ({ data, location }) => {
  const { site, markdownRemark } = data
  const { frontmatter, html, excerpt } = markdownRemark

  return (
    <Layout
      title={`${frontmatter.title} | ${site.siteMetadata.title}`}
      description={excerpt}
      pathname={`${location.pathname}${location.pathname.substr(-1) !== '/' ? '/' : ''}`}
      og={[
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: `${frontmatter.title} | ${site.siteMetadata.title}` },
        { property: 'og:description', content: excerpt },
        { property: 'og:image', content: frontmatter.image ? `${site.siteMetadata.siteUrl}${frontmatter.image.childImageSharp.resize.src}` : null },
        { property: 'og:image:width', content: frontmatter.image ? frontmatter.image.childImageSharp.resize.width : null },
        { property: 'og:image:height', content: frontmatter.image ? frontmatter.image.childImageSharp.resize.height : null },
        { property: 'article:published_time', content: frontmatter.dateTime },
        { property: 'article:modified_time', content: frontmatter.dateTime },
      ].concat(frontmatter.tags.map(tag => ({ property: 'article:tag', content: tag.name })))}
      twitter={[
        { name: 'card', content: frontmatter.image ? 'summary_large_image' : 'summary' },
        { name: 'title', content: `${frontmatter.title} | ${site.siteMetadata.title}` },
        { name: 'description', content: excerpt },
        { name: 'image', content: frontmatter.image ? `${site.siteMetadata.siteUrl}${frontmatter.image.childImageSharp.resize.src}` : null },
        { name: 'label1', content: 'Written by' },
        { name: 'data1', content: 'Yasmine Awwad' },
        { name: 'label2', content: frontmatter.tags.length > 0 ? 'Posted in' : null },
        { name: 'data2', content: frontmatter.tags.length > 0 ? frontmatter.tags.map(tag => tag.name).join(', ') : null },
      ]}
    >
      <Post
        frontmatter={frontmatter}
        html={html}
        page={frontmatter.slug === 'about' || frontmatter.slug === 'places'}
      />

      {(frontmatter.slug !== 'about' && frontmatter.slug !== 'places') && (
        <DiscussionEmbed
          shortname="peekingduck"
          config={{
            url: `${site.siteMetadata.siteUrl}${location.pathname}`,
            identifier: frontmatter.id,
            title: frontmatter.title,
          }}
        />
      )}
    </Layout>
  )
}

export default PostPage